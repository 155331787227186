import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { format } from "date-fns";

import { Kns } from "lib/types";
import { dispatcherDuck, journalDuck } from "lib/ducks";
import { DeviceEmergencyCodeEnum, pumpStatuseEnum } from "lib/enums";
import { DeviceWorkStatus, EngineWork, StyledTime } from "components/common";
import { Divider } from "components/ui";
import { ITEM_WIDTH } from "../helpers";

import {
  StyledStackItem,
  StyledKnsName,
  StyledAddresses,
  StyledStackListItem,
  StyledListItemContainer,
} from "./styled";
import { ListItemIcons } from "./ListItemIcons";
import { KnsIcon } from "./KnsIcon";
import { getLiquidLevel } from "lib/helpers";

interface Props {
  search: string;
}
export const ListItems: FC<Props> = observer(({ search }) => {
  const { active } = dispatcherDuck;
  const knses = dispatcherDuck.getListKns();
  const list = [
    ...knses.filter((kns) => dispatcherDuck.checkError(kns)),
    ...knses.filter((kns) => !dispatcherDuck.checkError(kns)),
  ].filter((kns: Kns) => {
    return (
      kns.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
    );
  });

  const handleClick = (kns: Kns) => () => {
    if (dispatcherDuck.active?.id === kns.id) {
      dispatcherDuck.active = null;
      journalDuck.activeKns = null;
    } else {
      dispatcherDuck.active = kns;
      journalDuck.activeKns && (journalDuck.activeKns = kns);
    }
  };

  return (
    <StyledListItemContainer container>
      {list.map((item: Kns, index: number) => {
        const isActive = active?.id === item.id;
        const isUpperSensorError = item.emergencyCode.includes(
          DeviceEmergencyCodeEnum.upperSensorError,
        );
        const liquidLevel = getLiquidLevel(item, isUpperSensorError);
        return (
          <>
            {index !== 0 && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
            <Grid item xs={12} sx={{ pt: 1, pb: 1 }} key={item.id}>
              <Stack
                direction="row"
                spacing={1}
                divider={
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ borderColor: "transparent" }}
                  />
                }>
                <StyledStackItem style={{ width: ITEM_WIDTH[2] }}>
                  <KnsIcon kns={item} />
                </StyledStackItem>

                <StyledStackListItem style={{ width: ITEM_WIDTH[0] }}>
                  <Stack
                    direction="row"
                    style={{
                      width: "100%",
                      justifyContent: "space-between",
                    }}>
                    <div>
                      <StyledKnsName>
                        {item.name ? item.name : `КНС №${item.deviceCode}`}
                      </StyledKnsName>
                      <StyledAddresses>
                        {item?.location?.shortAddress ?? "-"}
                      </StyledAddresses>
                    </div>
                    <div style={{ alignItems: "center", display: "flex" }}>
                      <IconButton
                        size="small"
                        onClick={handleClick(item)}
                        color={isActive ? "primary" : "default"}>
                        <ArrowForwardIos
                          sx={{
                            width: 16,
                            height: 16,
                            rotate: `${isActive ? 90 : 0}deg`,
                          }}
                        />
                      </IconButton>
                    </div>
                  </Stack>
                </StyledStackListItem>

                <StyledStackItem style={{ width: ITEM_WIDTH[1] }}>
                  <DeviceWorkStatus workStatus={item.workStatus} />
                </StyledStackItem>

                <StyledStackItem style={{ width: ITEM_WIDTH[1] }}>
                  <EngineWork
                    on={
                      item?.pumpStatuses?.includes(pumpStatuseEnum.on) ?? false
                    }
                  />
                </StyledStackItem>

                <StyledStackItem style={{ width: ITEM_WIDTH[1] }}>
                  <Typography color={liquidLevel > 119 ? "error" : "default"}>
                    {liquidLevel} %
                  </Typography>
                </StyledStackItem>

                <StyledStackItem style={{ flexGrow: 1 }}>
                  <div style={{ display: "flex", gap: "2px" }}>
                    <ListItemIcons kns={item} />
                  </div>
                </StyledStackItem>
                <StyledStackItem style={{ width: ITEM_WIDTH[4] }}>
                  <StyledTime>
                    {format(new Date(item.updated), "HH:mm:ss")}
                  </StyledTime>
                </StyledStackItem>
              </Stack>
            </Grid>
          </>
        );
      })}
    </StyledListItemContainer>
  );
});
