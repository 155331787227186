import { runInAction, makeAutoObservable, toJS } from "mobx";
import * as signalR from "@microsoft/signalr";
import { uniqBy } from "lodash";

import { knsApi, vnsApi } from "lib/api";
import { DeviceEmergencyCodeEnum, FacilityTypeEnum } from "lib/enums";
import { Kns, KnsMessage, Vns } from "lib/types";
import { HUB_URL } from "lib/config";

type Filter = {
  organizationUnitId: number | null;
  type: FacilityTypeEnum | null;
};
class Dispatcher {
  private isPending: boolean = false;
  private intervalId: NodeJS.Timer | null = null;

  public knses: Kns[] = [];
  public vnses: Vns[] = [];
  public page: number = 1;
  public isLoading: boolean = false;
  // сортировка по отображению на карте, т.е показывать первыми те что на карте видны
  public isTracking: boolean = false;
  // текущие кнс/внс в области видимости на карте
  public trackingKns: Kns[] = [];
  public trackingVns: Vns[] = [];
  // отображать/скрывать список кнс/внс
  public showList: boolean = true;
  public active: Kns | Vns | KnsMessage | null = null;
  public filter: Filter = {
    organizationUnitId: null,
    type: null,
  };

  constructor() {
    makeAutoObservable(this);
  }

  private findIndex = (deviceCode: number) => {
    let type = FacilityTypeEnum.KNS;
    let idx = this.knses.findIndex((kns: Kns) => kns.deviceCode === deviceCode);

    if (idx === -1) {
      idx = this.vnses.findIndex((vns: Vns) => vns.deviceCode === deviceCode);
      type = FacilityTypeEnum.VNS;
    }

    return { idx, type };
  };

  private hasError(arr: (Kns | Vns)[]) {
    return arr
      .filter((knsVns: Kns | Vns) => knsVns.onRepair !== true)
      .map((knsVns: Kns | Vns) => knsVns.emergencyCode)
      .flat()
      .some(
        (emergencyCode) =>
          emergencyCode !== DeviceEmergencyCodeEnum.nonEmergency,
      );
  }

  private async fetch() {
    const { data: knses } = await knsApi.fetch({
      page: this.page,
      pageSize: 40,
    });
    const { data: vnses } = await vnsApi.fetch({
      page: this.page,
      pageSize: 40,
    });
    this.setKnses(knses);
    this.setVnses(vnses);
    if (this.active) {
      if (this.active.type === FacilityTypeEnum.KNS) {
        knses.forEach((kns: Kns) => {
          if (this.active?.id === kns.id) {
            runInAction(() => {
              this.active = kns;
            });
          }
        });
      } else {
        vnses.forEach((vns: Vns) => {
          if (this.active?.id === vns.id) {
            runInAction(() => {
              this.active = vns;
            });
          }
        });
      }
    }
  }

  private async initInterval() {
    if (this.intervalId === null) {
      this.intervalId = setInterval(async () => {
        if (this.isPending === false) {
          this.isPending = true;
          await this.fetch();
          this.isPending = false;
        }
      }, 10000);
    }
  }

  eventKns(event: MessageEvent) {
    let data = JSON.parse(event.data);
    const { idx, type } = this.findIndex(data.deviceCode);
    const dateUpdate = new Date();
    data = { ...data, messageId: data.id };
    switch (type) {
      case FacilityTypeEnum.KNS:
        data = { ...data, id: data.knsId };
        break;
      default:
        data = { ...data, id: data.vnsId };
    }

    if (idx !== -1) {
      switch (type) {
        case FacilityTypeEnum.KNS:
          this.knses[idx] = {
            ...this.knses[idx],
            ...data,
            dateUpdate,
          };
          break;
        default:
          this.vnses[idx] = {
            ...this.vnses[idx],
            ...data,
            dateUpdate,
          };
      }
    }

    if (this.active?.deviceCode === data.deviceCode) {
      this.active = {
        ...this.active,
        ...data,
        dateUpdate,
      };
    }
  }

  websocketKns(knsData: KnsMessage) {
    const { pumpsCount, ...kns } = knsData;
    const { idx } = this.findIndex(kns.deviceCode);

    const dateUpdate = new Date(kns.created);
    const data = { ...kns, messageId: kns.id, id: kns.knsId };
    if (idx !== -1) {
      this.knses[idx] = {
        ...this.knses[idx],
        ...data,
        dateUpdate,
      };
    }
  }

  checkError(data: Kns | Vns): boolean {
    return [
      DeviceEmergencyCodeEnum.engine1Overload,
      DeviceEmergencyCodeEnum.engine2Overload,
      DeviceEmergencyCodeEnum.engine3Overload,
      DeviceEmergencyCodeEnum.enginesLowEfficiency,
      DeviceEmergencyCodeEnum.lowerSensorError,
      DeviceEmergencyCodeEnum.upperSensorError,
      DeviceEmergencyCodeEnum.pitOverflow,
      DeviceEmergencyCodeEnum.roomFlooding,
      DeviceEmergencyCodeEnum.emergencyButton,
      DeviceEmergencyCodeEnum.phaseControlError,
    ].some((error) => {
      return data.emergencyCode?.includes(error) ?? false;
    });
  }

  setKnses(knses: Kns[]) {
    this.knses = knses;
  }

  setVnses(vnses: Vns[]) {
    this.vnses = vnses;
  }

  async fetchData() {
    runInAction(() => {
      this.isLoading = true;
    });
    try {
      await this.fetch();
      // this.initInterval();
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  getActive() {
    return toJS(this.active);
  }

  getList(): (Kns | Vns)[] {
    let res = [];
    switch (this.filter.type) {
      case FacilityTypeEnum.KNS:
        res = toJS([...this.trackingKns, ...this.knses]);
        break;
      case FacilityTypeEnum.VNS:
        res = toJS([...this.trackingVns, ...this.vnses]);
        break;
      default:
        res = toJS([
          ...this.trackingKns,
          ...this.trackingVns,
          ...this.knses,
          ...this.vnses,
        ]);
        break;
    }
    return uniqBy(
      res.filter((knsVns: Kns | Vns) => {
        return this.filter.organizationUnitId !== null
          ? knsVns.organizationUnitId === this.filter.organizationUnitId
          : true;
      }),
      "id",
    );
  }

  getListKns(): Kns[] {
    const res = toJS([...this.trackingKns, ...this.knses]);
    return uniqBy(
      res.filter((knsVns: Kns | Vns) => {
        return this.filter.organizationUnitId !== null
          ? knsVns.organizationUnitId === this.filter.organizationUnitId
          : true;
      }),
      "id",
    );
  }

  getAllListKns(): Kns[] {
    const res = toJS([...this.trackingKns, ...this.knses]);
    return uniqBy(res, "id");
  }

  hasErrorKns(organizationUnitId?: number | null) {
    return this.hasError(
      organizationUnitId
        ? this.knses.filter((kns) =>
            organizationUnitId !== null
              ? kns.organizationUnitId === organizationUnitId
              : true,
          )
        : this.knses,
    );
  }

  hasErrorVns(organizationUnitId?: number | null) {
    return this.hasError(
      organizationUnitId
        ? this.vnses.filter((vns) =>
            organizationUnitId !== null
              ? vns.organizationUnitId === organizationUnitId
              : true,
          )
        : this.vnses,
    );
  }
}

const dispatcher = new Dispatcher();

const connection = new signalR.HubConnectionBuilder()
  .withUrl(`${HUB_URL}/kns`, {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets,
  })
  .build();
connection.on("SendMessage", (data) => {
  dispatcher.websocketKns(data);
});
connection.start();

export default dispatcher;
